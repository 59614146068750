import $ from 'jquery';
import '../css/greater-love-form.scss';

const greaterLoveFormData = {
  forms: [],
};

/**
 * The Greater Love Form Handler.
 *
 * @param {object} [options]
 * @param {object} [options.on]
 * @param {callback} [options.on.init]
 * @param {callback} [options.on.validating]
 * @param {callback} [options.on.validated]
 * @param {callback} [options.on.submitting]
 * @param {callback} [options.on.submitted]
 * @param {callback} [options.on.error]
 * @param {callback} [options.on.success]
 * @param {callback} [options.class_names.invalid]
 * @param {string} [options.message_container] A CSS selector for the message container. You can disable setting the message by setting
 *                                             this value to false.
 * @param {function} [options.invalid_field_target] Set a custom target to set the is-invalid class to.
 */
window.greaterLoveForm = function (options = {}) {
  options = Object.assign(
    {},
    {
      /**
       * Customize the target, which should receive the is-invalid class.
       * @param {jQuery} $input
       */
      invalid_field_target($input) {
        return $input;
      },
      class_names: {
        //
      },
    },
    options
  );

  const classNames = Object.assign(
    {},
    {
      invalid: 'is-invalid',
    },
    options.class_names
  );

  $('form[data-wly-form]').each(function (index) {
    let submitted = false;

    const lang = document.documentElement.lang;
    const $form = $(this);
    const $submitButton = $form.find('[type="submit"]').first();
    const dirtyFields = [];
    const conditionalLogic = JSON.parse(this.dataset.conditionalLogic ?? '{}');
    const hiddenFields = {};

    init();

    function fireEvent(event, data = {}) {
      $form.get(0).dispatchEvent(
        new CustomEvent(event, {
          bubbles: false,
          detail: data,
        })
      );

      if (options.hasOwnProperty('on') && options.on.hasOwnProperty(event) && typeof options.on[event] === 'function') {
        options.on[event]({
          form: $form,
          data,
        });
      }
    }

    function init() {
      // Make sure to not init the same node twice
      if (greaterLoveFormData.forms.length > 0 && greaterLoveFormData.forms.filter((f) => f.isSameNode($form.get(0))).length > 0) {
        return;
      }

      $form.submit(onFormSubmit);
      $form.find('input[type=text], input[type=email], textarea').focusout(onInputChanged);
      $form.find('input[type=checkbox], input[type=radio], select').change(onInputChanged);
      $form.find('input,textarea').on('input', conditionalCheck);

      greaterLoveFormData.forms.push($form.get(0));

      if (Object.keys(conditionalLogic)?.length) {
        conditionalCheck().then(() => {
          $form.removeAttr('data-wly-form-cloak');
          fireEvent('init');
        });
      } else {
        $form.removeAttr('data-wly-form-cloak');
        fireEvent('init');
      }
    }

    function scrollToElement($element, margin = 200) {
      const offset = $element.offset(); // Contains .top and .left

      // Scroll a little further to the top
      offset.top -= margin;

      $('html, body').animate({
        scrollTop: offset.top,
      });
    }

    function clearErrorFields(clearRecaptcha) {
      let $fieldWrappers = $form.find('[data-field-id]');

      if (clearRecaptcha) {
        $fieldWrappers = $fieldWrappers.not('[data-field-id="g-recaptcha-response"]');
      }

      $fieldWrappers.find('[data-error-message]').text('');
      $fieldWrappers.removeClass(classNames.invalid);
      $fieldWrappers.find('[name]').each(function () {
        options.invalid_field_target($(this)).removeClass(classNames.invalid);

        // Some fields may not have the possibility to set a custom validity.
        if (typeof this.setCustomValidity === 'function') {
          this.setCustomValidity('');
        }
      });
    }

    function handleErrors(errors) {
      // Only display errors for fields that are dirty or consider all fields if the form has been submitted
      const dirtyErrors = submitted ? Object.keys(errors) : Object.keys(errors).filter((key) => dirtyFields.includes(key));

      for (const key of dirtyErrors) {
        const $fieldWrapper = $form.find('[data-field-id="' + key + '"]').first();
        const $inputField = $fieldWrapper.find('[name="' + key + '"]').first();
        const $errorField = $fieldWrapper.find('[data-error-message]').first();

        // Show error message
        $errorField.html(Object.values(errors[key]).join('<br/>'));

        // Add invalid class to field wrapper
        $fieldWrapper.addClass(classNames.invalid);
        options.invalid_field_target($inputField).addClass(classNames.invalid);

        if ($inputField.get(0) && typeof $inputField.get(0).setCustomValidity === 'function') {
          $inputField.get(0).setCustomValidity(Object.values(errors[key])[0]);
        }
      }
    }

    function handleErrorResponse(res) {
      const body = res.responseJSON;

      if (body && body.errors && Object.keys(body.errors).length > 0) {
        handleErrors(body.errors);
      }

      fireEvent('error', res);
    }

    function handleSuccessResponse(body) {
      if (!body?.success || body?.errors) {
        if (body && body.errors && Object.keys(body.errors).length > 0) {
          handleErrors(body.errors);
          return;
        }
      }

      const $message = $('<div class="success-message" />');
      $message.html(body.success_message.trim());

      let $parent = $form.parent();

      $form.hide();

      if (options.message_container) {
        $(options.message_container).get(0).append($message);
      } else if (options.message_container === false) {
        // Do not append message if it is explicitly set to false
      } else {
        $parent.append($message);
      }

      fireEvent('success', {
        parent: $parent,
        response: body,
      });

      scrollToElement($message);
    }

    async function onInputChanged(e) {
      await conditionalCheck();

      const $field = $(e.target);
      let name = $field.attr('name');

      if (name === undefined) {
        return;
      }

      // Normalize the name
      name = name.replace('[]', '');

      if (!dirtyFields.includes(name)) {
        dirtyFields.push(name);
      }

      validateForm();
    }

    function validateForm() {
      fireEvent('validating');

      $submitButton.addClass('is-validating');

      $.ajax({
        type: 'POST',
        url: '/wp-json/weloveyou/v1/form/validate' + (lang ? '?lang=' + lang : ''),
        data: getFormValuesAsFormData(),
        processData: false,
        contentType: false,
      })
        .always(() => fireEvent('validated'))
        .always(() => $submitButton.removeClass('is-validating'))
        .always(() => clearErrorFields(false))
        .fail(handleErrorResponse);
    }

    function promisedTimeout(handler) {
      return new Promise((resolve) => {
        setTimeout(() => {
          const result = handler();

          if (result instanceof Promise) {
            result.then((res) => {
              resolve();
            });
          } else {
            resolve();
          }
        });
      });
    }

    /**
     * Get all form values as an object. Empty fields will be filled with an empty array to make sure that
     * they are part of the request. This is necessary because for conditional fields the backend checks
     * whether a field is present in the payload and only checks the required condition if it is actually present.
     *
     * If a field is not visible it will also not be included in the payload.
     *
     * @param includeFiles
     * @return {{}}
     */
    function getFormValues(includeFiles = false) {
      const formValues = {};

      // The FormData does not include empty checkbox and radio fields. However, we need to save at least an empty value to the property, so that it will be included
      // in the validation and submit request, like other empty text fields.
      [...new Set(Array.from($form.find('[name$="[]"]').map((index, f) => f.getAttribute('name'))))]
        .map((name) => name.replace('[]', ''))
        .forEach((name) => {
          formValues[name] = [];
        });

      [...new Set(Array.from($form.find('[name][type="radio"]').map((index, f) => f.getAttribute('name'))))].forEach((name) => {
        formValues[name] = '';
      });

      new FormData($form.get(0)).entries().forEach(([name, value]) => {
        if (name.endsWith('[]')) {
          name = name.replace('[]', '');

          if (!Array.isArray(formValues[name])) {
            formValues[name] = [];
          }

          formValues[name].push(value);
        } else {
          if (value instanceof File) {
            if (includeFiles) {
              formValues[name] = value;
            }
          } else {
            formValues[name] = value;
          }
        }
      });

      return formValues;
    }

    function getFormValuesAsFormData(includeFiles = false) {
      const formValues = getFormValues(includeFiles);

      const data = new FormData();

      Object.keys(formValues).forEach((name) => {
        if (Array.isArray(formValues[name])) {
          if (!formValues[name].length) {
            data.append(name, '');
          } else {
            formValues[name].forEach((value) => {
              data.append(`${name}[]`, value);
            });
          }
        } else {
          data.append(name, formValues[name]);
        }
      });

      return data;
    }

    function conditionalCheck(currentLoop = 0) {
      return promisedTimeout(() => {
        const formValues = getFormValues();

        for (const currentFieldName in conditionalLogic) {
          const ruleConfig = conditionalLogic[currentFieldName];
          const ruleMatches = [];
          const rules = ruleConfig.rules;
          const relation = ruleConfig.relation ?? 'or';

          // Check all the conditions for the current field
          for (const rule of rules) {
            const { field: compareFieldName, condition, value } = rule;

            const compareValue = formValues[compareFieldName] ?? null;
            let conditionMatch = false;

            if (condition === 'is' && compareValue === value) conditionMatch = true;
            else if (condition === 'is not' && compareValue !== value) conditionMatch = true;
            else if (condition === 'is empty' && !compareValue) conditionMatch = true;
            else if (condition === 'is not empty' && !!compareValue) conditionMatch = true;
            else if (condition === 'contains' && compareValue?.includes(value)) conditionMatch = true;
            else if (condition === 'does not contain' && !compareValue?.includes(value)) conditionMatch = true;
            else if (condition === 'is greater' && parseFloat(compareValue) > parseFloat(value)) conditionMatch = true;
            else if (condition === 'is less' && parseFloat(compareValue) < parseFloat(value)) conditionMatch = true;

            if (conditionMatch) {
              ruleMatches.push(true);
            } else {
              ruleMatches.push(false);
            }
          }

          if ((relation === 'and' && ruleMatches.filter(Boolean).length === ruleMatches.length) || (relation === 'or' && ruleMatches.filter(Boolean).length)) {
            if (hiddenFields[currentFieldName]) {
              hiddenFields[currentFieldName].$commentField.replaceWith(hiddenFields[currentFieldName].$fieldWrapper);

              delete hiddenFields[currentFieldName];
              delete formValues[currentFieldName];

              if (currentLoop <= 10) {
                return conditionalCheck(currentLoop + 1);
              }
            }
          } else {
            const $fieldWrapper = $form.find('[data-field-id="' + currentFieldName + '"]').first();
            const wasHidden = !!hiddenFields[currentFieldName];

            if (!hiddenFields[currentFieldName]) {
              const $commentField = $(document.createComment('form-if')).first();

              hiddenFields[currentFieldName] = {
                $commentField,
                $fieldWrapper: $fieldWrapper.clone(true),
              };
            }

            $fieldWrapper?.replaceWith(hiddenFields[currentFieldName].$commentField);

            if (!wasHidden && currentLoop <= 10) {
              return conditionalCheck(currentLoop + 1);
            }
          }
        }
      });
    }

    function onFormSubmit(e) {
      e.preventDefault();

      fireEvent('submitting');

      $form.addClass('is-submitting');
      $submitButton.addClass('is-submitting');
      submitted = true;

      $.ajax({
        type: 'POST',
        url: '/wp-json/weloveyou/v1/form/submit' + (lang ? '?lang=' + lang : ''),
        data: getFormValuesAsFormData(true),
        processData: false,
        contentType: false,
      })
        .always(() => $form.removeClass('is-submitting'))
        .always(() => $submitButton.removeClass('is-submitting'))
        .always(() => fireEvent('submitted'))
        .always(() => clearErrorFields(true))
        .always(() => {
          if (window.hasOwnProperty('grecaptcha')) {
            try {
              grecaptcha.reset();
            } catch (err) {
              // Usually we can ignore this error, for instance if no reCAPTCHA clients exists.
              console.warn(err);
            }
          }
        })
        .done(handleSuccessResponse)
        .fail(handleErrorResponse);

      return false;
    }
  });
};
